import React, { useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import { navigate } from 'gatsby';
import * as Yup from 'yup';

import Layout from '../../components/Layout/Layout';
import Button from '../../components/UI/Button/Button';
import InputWrapper from '../../components/UI/InputWrapper/InputWrapper';
import InputPassword from '../../components/UI/InputPassword/InputPassword';
import Label from '../../components/UI/Label/Label';
import LandingInfoWrapper from '../../components/Landing-info/LandingInfoWrapper/LandingInfoWrapper';
import {
  parseSearchParams,
  replaceStringSpacesByPlus,
} from '../../helpers/helper-methods';
import { Input } from '../../components/UI/Input/Input';
import { AUTH } from '../../constans/form-fields';
import { API } from '../../constans/http';
import http from '../../services/http.service';
import StorageService from '../../services/storage.service';
import { LOCALSTORAGE_KEYS } from '../../constans/localstorage';
import {
  EMAIL_INVALID,
  EMAIL_REQUIRED,
  PASSWORD_INVALID_TIP,
  PASSWORD_REQUIRED,
  PASSWORD_VALID,
} from '../../constans/error-messages';
import {
  EMAIL_MAX,
  PWD_MAX,
  PWD_MIN,
  REGEXP_NOSPACES,
  REGEXP_ONE_CAPITAL,
  REGEXP_ONE_DIGIT,
  REGEXP_ONE_LETTER,
} from '../../constans/validation';

import styles from './index.module.scss';

const validationSchema = Yup.object({
  email: Yup.string()
    .required(EMAIL_REQUIRED)
    .email(EMAIL_INVALID)
    .max(EMAIL_MAX),
  password: Yup.string()
    .required(PASSWORD_REQUIRED)
    .matches(REGEXP_NOSPACES, PASSWORD_VALID)
    .matches(REGEXP_ONE_LETTER, PASSWORD_VALID)
    .matches(REGEXP_ONE_CAPITAL, PASSWORD_VALID)
    .matches(REGEXP_ONE_DIGIT, PASSWORD_VALID)
    .min(PWD_MIN, PASSWORD_VALID)
    .max(PWD_MAX, PASSWORD_VALID),
});

const Invitation = ({ location }) => {
  const storeService = useMemo(() => StorageService(), []);
  const [loading, setLoading] = useState(false);
  const [isLinkBroken, setIsLinkBroken] = useState(false);

  const params = useMemo(() => {
    const parsedParams = parseSearchParams(location);

    if (!parsedParams.email || !parsedParams.token) {
      setIsLinkBroken(true);
    }

    return parsedParams;
  }, [location]);

  const initialValues = {
    email: replaceStringSpacesByPlus((params && params.email) || '') || '',
    token: params.token || '',
    password: '',
  };

  const handleSubmit = (values) => {
    setLoading(true);

    http
      .post(API.BECOME_A_SUPLIER, { ...values })
      .then(() => {
        http
          .post(API.USER_LOGIN, {
            email: values.email,
            password: values.password,
          })
          .then(({ data: { session, user } }) => {
            storeService.set(LOCALSTORAGE_KEYS.SESSION, session);
            storeService.set(LOCALSTORAGE_KEYS.USER, user);
            navigate('/sellers/my-properties');
          })
          .catch(() => {
            /* do nothing */
          });
      })
      .catch(() => {
        /* do nothing */
      })
      .finally(() => setLoading(false));
  };

  return (
    <Layout mainClasses={styles.pageWrapper} noFooter>
      <div className={`${styles.wrapper} container`}>
        <div className={`${styles.formWrapper} m-auto`}>
          {!isLinkBroken ? (
            <>
              <p className={`${styles.formTitle} t-700`}>Set a Password</p>
              <Formik
                validateOnMount
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={handleSubmit}
              >
                {({ isValid, errors }) => (
                  <Form>
                    <InputWrapper>
                      <Label>Email</Label>
                      <Input
                        type="email"
                        name={AUTH.email.name}
                        placeholder={AUTH.email.placeHolder}
                      />
                    </InputWrapper>
                    <InputWrapper>
                      <Label>Temporary Password</Label>
                      <div>
                        <InputPassword
                          hideError
                          name={AUTH.password.name}
                          placeholder={AUTH.password.placeHolder}
                        />
                        <div className="password-tip">
                          {errors.password ? (
                            PASSWORD_INVALID_TIP
                          ) : (
                            <span className="success-color">
                              {PASSWORD_VALID}
                            </span>
                          )}
                        </div>
                      </div>
                    </InputWrapper>
                    <Button
                      disabled={!isValid || loading}
                      classes="btn btn_block btn-uppercased btn_common btn_auth t-600"
                      type="submit"
                    >
                      Create an account
                    </Button>
                  </Form>
                )}
              </Formik>
              <div className={`${styles.agreeBlock} t-500`}>
                By tapping &quot;Create an account&quot; button you are agreed
                to the
              </div>
              <LandingInfoWrapper
                btnsWrapper={styles.infoWrapper}
                btn={styles.infoBtn}
                delimiter={<span className={styles.infoDelimiter}>&amp;</span>}
              />
            </>
          ) : (
            <p className={`${styles.formTitle} t-700 text-center`}>
              Invalid invitation link
            </p>
          )}
        </div>
      </div>
    </Layout>
  );
};

export default Invitation;
